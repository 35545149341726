import React, { useEffect } from 'react';
import { useToast } from '~components/toast/use-toast';
import Typography from '~components/typography';
import { Close, ProfileWarning, Tick } from '~svg-components';

enum toastTypes {
  success = 'success',
  error = 'error',
  like = 'like',
}
interface IToastProps {
  type?: toastTypes;
  onClose?: () => void;
  text: Element | string;
  uuid: number;
}

const ToastItem = React.forwardRef<any, IToastProps & React.HTMLAttributes<any>>(
  ({ type = toastTypes.error, uuid, onClose, text, ...props }, ref) => {
    const { removeToast } = useToast();
    useEffect(() => {
      const timer = setTimeout(() => {
        removeToast(uuid);
      }, 6000);

      return () => {
        clearTimeout(timer);
      };
    }, [uuid, removeToast]);
    return (
      <div ref={ref} {...props} className="toast-item">
        <div className="icon-container">
          {type === toastTypes.success && (
            <Tick width="16" height="12" fill="var(--color-purple_6)" />
          )}
          {type === toastTypes.error && <ProfileWarning width="16" height="16" />}
        </div>
        <Typography>{text}</Typography>
        {onClose && <Close onClick={onClose} width="16" height="16" />}
        <style jsx>
          {`
            .icon-container {
              margin-right: 8px;
            }
            .toast-item {
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-top: 12px;
              padding: 12px 24px;
              border-radius: 2px;
              box-shadow: 0px 0px 12px rgba(76, 76, 76, 0.25);
              background-color: var(--color-white);
              color: var(--color-purple_6);
            }
          `}
        </style>
      </div>
    );
  }
);

export default ToastItem;
