import { useMutation } from '@apollo/react-hooks';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useMemo, useRef } from 'react';
import * as Yup from 'yup';
import Button from '~components/button';
import Divider from '~components/divider';
import { Input } from '~components/form';
import { useThemeContext } from '~components/theme-provider';
import Typography from '~components/typography';
import { Authorization } from '~constants/cookie-properties';
import { SP_StudentLogin, SP_StudentLoginVariables } from '~graphql/__generated__/SP_StudentLogin';
import { STUDENT_LOGIN } from '~graphql/user';
import { Trans, useTranslation } from '~i18n';
import { setCookie } from '~utils/cookie-operation';

interface Values {
  email: string;
  password: string;
}
type SetError = {
  (field: string, message: string): void;
};

interface ILoginFormProps {
  onSuccess?: () => void;
  setModalType: (params: 'LOGIN' | 'CREATE_ACCOUNT' | 'FORGET_PASSWORD') => void;
}
const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
const STUDENT_USER_NOT_FOUND = 'STUDENT_USER_NOT_FOUND';
const LoginForm: React.FC<ILoginFormProps> = ({ onSuccess, setModalType }) => {
  const { t } = useTranslation(['student_portal', 'public']);
  const { ORG } = useThemeContext();

  const { topLandlordId } = useThemeContext();

  const submitCountRef = useRef<number>(0);
  const setFieldErrorFuc = useRef<SetError | null>(null);
  const validError = t('form.validation.password');
  const emailValidError = t('form.validation.format_error', {
    field_name: t('form.field.email_address').toLowerCase(),
  });
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(emailValidError)
      .required(),
    password: Yup.string()
      .trim()
      .min(8, validError)
      .matches(/(?=.*[a-zA-Z])(?=.*[0-9])./, validError)
      .required(),
  });

  const [studentLogin, { loading, error }] = useMutation<SP_StudentLogin, SP_StudentLoginVariables>(
    STUDENT_LOGIN,
    {
      onCompleted: data => {
        const token = data.studentLogin?.authToken;
        if (token) {
          setCookie(Authorization, `Bearer ${token}`, 30);
          if (onSuccess) onSuccess();
        }
      },
      onError: ({ graphQLErrors }) => {
        const { message } = graphQLErrors[0] || {};
        if (message === STUDENT_USER_NOT_FOUND) {
          setFieldErrorFuc.current!('email', t('form.validation.user_not_find'));
        }
        if (message === INVALID_CREDENTIALS) {
          setFieldErrorFuc.current!('password', t('form.validation.password_wrong_text'));
        }
      },
    }
  );

  const emailErrorComponent = useMemo(() => {
    if (error && error.message.includes(STUDENT_USER_NOT_FOUND)) {
      return (
        <Trans i18nKey="form.validation.user_not_find">
          No account found for this email.
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {
              setModalType('CREATE_ACCOUNT');
            }}
          >
            Create an account now?
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error, setModalType]);
  const passwordErrorComponent = useMemo(() => {
    if (error && error.message.includes(INVALID_CREDENTIALS) && submitCountRef.current > 2) {
      return (
        <Trans i18nKey="form.validation.password_wrong">
          The password you entered is incorrect.
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {
              setModalType('FORGET_PASSWORD');
            }}
          >
            {{ resetText: t('auth.lost_password') }}
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error, setModalType, t]);

  const loginForm = ({ values: formValues, submitCount }: FormikProps<Values>) => {
    submitCountRef.current = submitCount;
    return (
      <Form className="login-form">
        <Input
          name="email"
          type="text"
          errorComponent={emailErrorComponent}
          className="login-input"
          label={t('form.field.email_address')}
          placeholder={t('form.field.email_address')}
        />
        <Input
          name="password"
          className="login-input"
          type="password"
          errorComponent={passwordErrorComponent}
          label={t('form.field.password')}
          labelRight={
            <Typography
              variant="body3"
              textDecoration="underline"
              onClick={() => {
                setModalType('FORGET_PASSWORD');
              }}
            >
              {t('login.forgot_password')}
            </Typography>
          }
          placeholder={t('form.field.password')}
        />
        <Button loading={loading} className="create-account-submit" type="submit" fullWidth={true}>
          {t('auth.login')}
        </Button>
      </Form>
    );
  };

  const onSubmit = async (values: Values, { setFieldError }: FormikHelpers<Values>) => {
    setFieldErrorFuc.current = setFieldError;
    studentLogin({
      variables: {
        ...values,
        topLandlordId,
      },
    });
  };
  return (
    <div className="login-form-component">
      <Typography variant="h4">{t('auth.login')}</Typography>
      <div className="login-form-welcome">
        <Typography variant="body3">{t(`login.welcome.${ORG}`)}</Typography>
        <Divider className="login-form-divider" />
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={loginSchema}
        component={loginForm}
      />
      <style jsx>{`
        .login-form-welcome {
          display: none;
        }
        :global(.login-form) {
          width: 650px;
        }
        :global(.login-input) {
          margin-top: 24px;
          width: 100%;
        }
        :global(.create-account-submit) {
          margin-top: 32px;
          margin-bottom: 16px;
        }

        @media screen and (max-width: 768px) {
          .login-form-component {
            padding: 0 16px;
          }
          .login-form-welcome {
            display: block;
            margin-top: 4px;
          }
          :global(.login-form-divider) {
            margin-top: 12px !important;
          }
          .login-form-welcome {
            display: block;
          }
          :global(.login-form) {
            width: 100%;
          }
          :global(.login-input) {
            margin-top: 16px;
          }
          :global(.login-input:nth-child(1)) {
            margin-top: 32px;
          }
          :global(.create-account-submit) {
            margin-bottom: 12px;
          }
        }
      `}</style>
    </div>
  );
};

export default LoginForm;
