import { useLazyQuery } from '@apollo/react-hooks';
import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm } from '~components/modal';
import { UserModal } from '~components/user';
import { Authorization } from '~constants/cookie-properties';
import { SP_QueryStudentViewer } from '~graphql/__generated__/SP_QueryStudentViewer';
import { QUERY_STUDENT_VIEWER } from '~graphql/user';
import { RootState } from '~store';
import { updateViewerInfo } from '~store/user/actions';
import { getCookie } from '~utils/cookie-operation';
import { gtmDataLayer } from '~utils/use-checkout-stage';
import useYupConfig from '~utils/use-yup-config';

const AuthContext = createContext('');

function AuthProvider({ children, token }: any) {
  useYupConfig();
  const dispatch = useDispatch();
  const { modal, viewer, login } = useSelector((state: RootState) => state);
  const [studentViewer] = useLazyQuery<SP_QueryStudentViewer>(QUERY_STUDENT_VIEWER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.studentViewer) {
        gtmDataLayer({ userId: data.studentViewer?.studentInfo?.originId });
        dispatch(updateViewerInfo(data.studentViewer));
      }
    },
  });
  const isBrowser = process.browser;
  const authToken = token ?? getCookie(Authorization);
  const router = useRouter();

  const isNeednotGetStudentViewer =
    router.pathname.indexOf('/set-password') > -1 ||
    router.pathname.indexOf('/booking-summary-group') > -1;

  useEffect(() => {
    if (viewer?.studentInfo?.originId) {
      gtmDataLayer({ userId: viewer?.studentInfo?.originId });
    }
  }, [router.pathname]);

  useEffect(() => {
    if (isBrowser && authToken && !viewer.studentInfo && !isNeednotGetStudentViewer) {
      studentViewer();
    }
  }, [isBrowser, studentViewer, authToken, viewer, isNeednotGetStudentViewer]);

  return (
    <AuthContext.Provider value={authToken}>
      {children}
      {modal && (
        <Confirm
          isOpen={modal.message.isOpen}
          type={modal.icon}
          isShowClose={modal.message.isShowClose}
          loading={modal.message.loading}
          title={modal.message.title}
          content={modal.message.content}
          btnText={modal.message.btnText}
          onClose={modal.message.onClose}
          onConfirm={modal.message.onConfirm}
        />
      )}
      {login && (
        <UserModal
          isOpen={login.message.isOpen}
          propertyId={login.message.propertyId}
          type={login.message.type}
          onClose={login.message.onClose}
          onConfirm={login.message.onConfirm}
        />
      )}
    </AuthContext.Provider>
  );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
