import { useMutation } from '@apollo/react-hooks';
import { UAContext } from '@quentin-sommer/react-useragent';
import Tippy from '@tippy.js/react';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { $enum } from 'ts-enum-util';
import * as Yup from 'yup';
import Button from '~components/button';
import Divider from '~components/divider';
import { Checkbox, Input, NameInput, PhoneInput, Select, SelectRoot } from '~components/form';
import { BaseModal } from '~components/modal';
import { useThemeContext } from '~components/theme-provider';
import Typography from '~components/typography';
import VerificationModalContent from '~components/user/verification-modal-content';
import { Authorization } from '~constants/cookie-properties';
import {
  CustomFieldModelTypeOptions,
  DeviceOption,
  StudentCommunicationPreferencesOptions,
} from '~graphql/__generated__/globalTypes';
import {
  SP_PreRegisterStudentUser,
  SP_PreRegisterStudentUserVariables,
} from '~graphql/__generated__/SP_PreRegisterStudentUser';
import {
  SP_RegisterStudentUser,
  SP_RegisterStudentUserVariables,
} from '~graphql/__generated__/SP_RegisterStudentUser';
import { PRE_REGISTER_STUDENT_USER, REGISTER_STUDENT_USER } from '~graphql/user';
import { i18n, Trans, useTranslation } from '~i18n';
import { IconEyeHide, IconEyeShow } from '~svg-components';
import { setCookie } from '~utils/cookie-operation';
import { gtmDataLayer } from '~utils/use-checkout-stage';
import { useGetDefaultLanguageOption } from '~utils/use-language-option';
import { useGetPreferredLanguages } from '~utils/use-language-option';
import { usePersonDropdownOptions } from '~utils/use-person-dropdown-options';
import phoneCodeJson from '~variables/phone-code.json';

const STUDENT_USER_ALREADY_EXISTS = 'STUDENT_USER_ALREADY_EXISTS';
const CAPTCHA_VALIDATION_ERROR = 'CAPTCHA_VALIDATION_ERROR';
const CAPTCHA_EXPIRATION_ERROR = 'CAPTCHA_EXPIRATION_ERROR';

const customStyles = {
  menuList: () => ({
    paddingTop: 0,
    maxHeight: 300,
    overflow: 'scroll',
  }),
  group: () => ({
    paddingTop: 0,
  }),
  groupHeading: () => ({
    padding: 0,
  }),
};

const getPropertys = (data: any) => {
  if (!data) return [];
  let propertys: Array<any> = [];

  const orderByPropertyName = _.orderBy(data, 'name', 'asc');
  const orderByCountryName = _.orderBy(orderByPropertyName, 'country.name', 'asc');
  const formatedByCountry = _.groupBy(orderByCountryName, 'country.name');

  Object.keys(formatedByCountry).forEach(item => {
    propertys.push({
      label: item,
      options: formatedByCountry[item],
    });
  });

  return propertys?.map(item => {
    return {
      ...item,
      options: item?.options?.map((op: any) => {
        return {
          label: op.name,
          value: op.id,
          countryValue: item.label,
          cityValue: op.city.name,
          contactAreaCode: op.contactAreaCode,
          contactPhoneNumber: op.contactPhoneNumber,
          contactEmailAddress: op.contactEmailAddress,
        };
      }),
    };
  });
};

interface Values {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  communication_email: boolean;
  communication_phone: boolean;
  communication_textMessage: boolean;
  heardSource: any;
  interestedPropertyId: any;
  mobilePhoneAreaCode: any;
  mobilePhoneNumber: any;
  preferredLanguage: any;
  doubleOpt: boolean;
  validationToken: any;
}

interface ICreateAccountFormProps {
  onSuccess?: () => void;
  setModalType: (params: 'LOGIN' | 'CREATE_ACCOUNT' | 'FORGET_PASSWORD') => void;
  propertyId?: string;
}

type SetError = {
  (field: string, message: string): void;
};
const CreateAccountForm: React.FC<ICreateAccountFormProps> = ({
  onSuccess,
  setModalType,
  propertyId,
}) => {
  const { t } = useTranslation(['student_portal', 'public']);
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const preferredLanguages = useGetPreferredLanguages();
  const { propertyWithEnabledAcademicYear, ORG, topLandlordId } = useThemeContext();

  const setFieldErrorFuc = useRef<SetError | null>(null);
  const [passwordType, setPasswordType] = useState('password');
  const [showPreferenceTips, setShowPreferenceTips] = useState(false);
  const [showDoubleConfirm, setShowDoubleConfirm] = useState(false);
  const [isVerificationError, setIsVerificationError] = useState(false);
  const [showVerificationPop, setShowVerificationPop] = useState(false);
  const counterCheckBox = useRef(0);
  const { heardSourceOptions } = usePersonDropdownOptions({
    variables: {
      topLandlordId,
      model: CustomFieldModelTypeOptions.STUDENT,
    },
  });

  const { list } = phoneCodeJson;
  const phoneValidError = t('form.validation.format_error', {
    field_name: t('form.field.phone').toLowerCase(),
  });
  const phoneValidTooLongError = t('form.validation.format_error_too_long', {
    number: 64,
  });
  const emailValidError = t('form.validation.format_error', {
    field_name: t('form.field.email_address').toLowerCase(),
  });
  const CreateAccountSchema = Yup.object().shape({
    firstName: Yup.string()
      .trim()
      .required(),
    lastName: Yup.string()
      .trim()
      .required(),
    email: Yup.string()
      .trim()
      .email(emailValidError)
      .required(),
    password: Yup.string()
      .trim()
      .min(8, t('form.validation.password'))
      .matches(/(?=.*[a-zA-Z])(?=.*[0-9])./, t('form.validation.password'))
      .required(),
    heardSource: Yup.mixed().required(),
    interestedPropertyId: Yup.mixed().required(),
    mobilePhoneAreaCode: Yup.mixed(),
    mobilePhoneNumber: Yup.string()
      .max(64, phoneValidTooLongError)
      .matches(/^[0-9]*$/, phoneValidError)
      .typeError(phoneValidError),
    preferredLanguage: Yup.mixed().required(),
    validationToken: Yup.mixed().when('firstName', () =>
      showVerificationPop
        ? Yup.string()
            .trim()
            .required()
        : Yup.string()
    ),
  });

  const [registerStudentUser, { loading, error }] = useMutation<
    SP_RegisterStudentUser,
    SP_RegisterStudentUserVariables
  >(REGISTER_STUDENT_USER, {
    onCompleted: data => {
      const token = data.registerStudentUser?.authToken;
      gtmDataLayer({
        event: 'register',
        userId: data.registerStudentUser?.studentUser?.studentInfo?.originId,
      });
      if (token) {
        setCookie(Authorization, `Bearer ${token}`, 30);
        if (onSuccess) onSuccess();
      }
    },
    onError: ({ graphQLErrors }) => {
      const { message } = graphQLErrors[0] || {};
      if (message === STUDENT_USER_ALREADY_EXISTS) {
        setFieldErrorFuc.current!('email', t('auth.have_account'));
      }
      if (message === CAPTCHA_EXPIRATION_ERROR) {
        setFieldErrorFuc.current!('validationToken', t('code_is_expired'));
      }
      if (message === CAPTCHA_VALIDATION_ERROR) {
        setFieldErrorFuc.current!('validationToken', t('code_is_incorrect'));
      }
    },
  });

  const [preRegisterStudentUser, { data, loading: preRegisterStudentUserLoading }] = useMutation<
    SP_PreRegisterStudentUser,
    SP_PreRegisterStudentUserVariables
  >(PRE_REGISTER_STUDENT_USER, {
    onCompleted: () => {
      setShowVerificationPop(true);
    },
    onError: ({ graphQLErrors }) => {
      const { message } = graphQLErrors[0] || {};
      if (message === STUDENT_USER_ALREADY_EXISTS) {
        setFieldErrorFuc.current!('email', t('auth.have_account'));
      } else {
        setIsVerificationError(true);
        setShowVerificationPop(true);
      }
    },
  });

  const formedPhoneCodes = list.map(phoneItem => ({
    label: `${phoneItem.phoneCode} (${phoneItem.name})`,
    value: phoneItem.phoneCode,
  }));
  const deduplicatePhoneCodes = formedPhoneCodes.filter((item, index) => {
    if (index !== 0 && _.isEqual(item, formedPhoneCodes[index - 1])) {
      return false;
    }
    return true;
  });

  const defaultLanguageOption = useGetDefaultLanguageOption(i18n.language);

  const errorComponent = useMemo(() => {
    if (error && error.message.includes(STUDENT_USER_ALREADY_EXISTS)) {
      return (
        <Trans i18nKey="form.validation.already_have_account">
          This email is already registered, want to
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {
              setModalType('LOGIN');
            }}
          >
            log in
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error, setModalType]);

  const formatInterestedProperty = getPropertys(propertyWithEnabledAcademicYear);

  const formatInputData = (values: any, communicationPreferences: any) => {
    const input: any = {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      communicationPreferences,
      heardSource: values.heardSource.value,
      validationToken: values.validationToken,
      interestedPropertyId: values.interestedPropertyId?.value,
      topLandlordId,
      device: $enum(DeviceOption).getValues()[isMobile ? 1 : 0],
      preferredLanguage: values?.preferredLanguage?.value,
    };

    if (values.mobilePhoneNumber) {
      input.mobilePhoneAreaCode = values.mobilePhoneAreaCode.value;
      input.mobilePhoneNumber = values.mobilePhoneNumber;
    }

    return input;
  };

  const onSubmit = (values: Values) => {
    const communicationPreferences = [];
    if (values.communication_email) {
      communicationPreferences.push(StudentCommunicationPreferencesOptions.EMAIL);
    }
    if (values.communication_phone) {
      communicationPreferences.push(StudentCommunicationPreferencesOptions.PHONE);
    }
    if (values.communication_textMessage) {
      communicationPreferences.push(StudentCommunicationPreferencesOptions.TEXT_MESSAGE);
    }
    if (!(communicationPreferences.length > 0 && !values.doubleOpt)) {
      const input = formatInputData(values, communicationPreferences);
      input.validationToken = values.validationToken;
      registerStudentUser({
        variables: { input },
      });
    }
  };

  const handleValidate = async (formik: any) => {
    setIsVerificationError(false);
    setFieldErrorFuc.current = formik.setFieldError;
    const result = await formik.validateForm();
    if (Object.keys(result).length > 0) {
      Object.keys(result).map(field => {
        formik.setFieldError(field, result[field]);
        formik.setFieldTouched(field, true);
        return true;
      });
    } else {
      const communicationPreferences = [];
      if (formik.values.communication_email) {
        communicationPreferences.push(StudentCommunicationPreferencesOptions.EMAIL);
      }
      if (formik.values.communication_phone) {
        communicationPreferences.push(StudentCommunicationPreferencesOptions.PHONE);
      }
      if (formik.values.communication_textMessage) {
        communicationPreferences.push(StudentCommunicationPreferencesOptions.TEXT_MESSAGE);
      }
      if (communicationPreferences.length > 0 && !formik.values.doubleOpt) {
        setShowPreferenceTips(true);
      } else {
        setShowPreferenceTips(false);

        const input = formatInputData(formik.values, communicationPreferences);
        delete input.validationToken;
        input.validationWidth = 163;
        input.validationHeight = 46;
        preRegisterStudentUser({ variables: { input } });
      }
    }
  };

  const handleRefresh = (formik: any) => {
    setIsVerificationError(false);
    formik.setFieldValue('validationToken', undefined);
    formik.setFieldTouched('validationToken', false);
    const communicationPreferences = [];
    if (formik.values.communication_email) {
      communicationPreferences.push(StudentCommunicationPreferencesOptions.EMAIL);
    }
    if (formik.values.communication_phone) {
      communicationPreferences.push(StudentCommunicationPreferencesOptions.PHONE);
    }
    if (formik.values.communication_textMessage) {
      communicationPreferences.push(StudentCommunicationPreferencesOptions.TEXT_MESSAGE);
    }
    const input = formatInputData(formik.values, communicationPreferences);
    delete input.validationToken;
    preRegisterStudentUser({ variables: { input } });
  };

  const handleCheck = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    setShowPreferenceTips(false);
    counterCheckBox.current = Math.max(
      0,
      counterCheckBox.current + (e.currentTarget.checked ? 1 : -1)
    );
    if (counterCheckBox.current > 0) {
      setShowDoubleConfirm(true);
    } else {
      setShowDoubleConfirm(false);
    }
  };
  const createAccountFormWrapper = (formik: any) => (
    <Form className="cretae-account-form">
      <Input
        name="email"
        type="text"
        errorComponent={errorComponent}
        className="create-account-input"
        label={t('form.field.email_address')}
        placeholder={t('form.field.email_address')}
      />
      <Input
        name="password"
        className="create-account-input"
        type={passwordType}
        label={t('form.field.password')}
        labelRight={
          passwordType === 'password' ? (
            <IconEyeHide
              onClick={() => {
                setPasswordType('text');
              }}
              width={16}
              height={13}
            />
          ) : (
            <IconEyeShow
              onClick={() => {
                setPasswordType('password');
              }}
              width={16}
              height={13}
            />
          )
        }
        placeholder={t('form.create.password')}
      />
      <NameInput
        firstName="firstName"
        lastName="lastName"
        type="text"
        className="create-account-input"
        label={t('form.field.your_name')}
        firstPlaceholder={t('form.field.first_name')}
        lastPlaceholder={t('form.field.last_name')}
        errorComponent={t('form.validation.missing_mandatory')}
      />
      <PhoneInput
        options={deduplicatePhoneCodes}
        label={t('form.field.mobile_phone_optional')}
        placeholder={t('form.field.mobile_phone')}
        name="mobilePhoneNumber"
        codeName="mobilePhoneAreaCode"
        className="create-account-input"
      />
      <Select
        className="create-account-input"
        selectType="custom"
        placeholder={t('form.filed.select')}
        options={heardSourceOptions}
        menuShouldScrollIntoView={false}
        label={t('form.field.hear_about_us')}
        name="heardSource"
      />

      {!propertyId && (
        <SelectRoot
          className="create-account-input"
          selectType="custom"
          placeholder={t('form.field.interested_property_placeholder')}
          options={formatInterestedProperty}
          menuShouldScrollIntoView={false}
          label={t('form.field.interested_property')}
          name="interestedPropertyId"
          styles={customStyles}
        />
      )}
      <Select
        className="create-account-input"
        selectType="custom"
        placeholder={t('form.filed.select')}
        options={preferredLanguages}
        menuShouldScrollIntoView={false}
        label={t('form.field.preferred_language')}
        name="preferredLanguage"
      />

      <div className="create-account-communication">
        <Typography variant="h9" component="div">
          {t('communication_perferences')}
        </Typography>
        <Typography variant="body3" component="div" className="create-account-communication__label">
          {t('communication_perferences.label')}
        </Typography>
      </div>
      <div className="create-account-check">
        <Checkbox
          name="communication_email"
          className="create-account-checkbox"
          labelClassName="create-account-label"
          label={t('form.field.email')}
          onClick={handleCheck}
        />
        <Checkbox
          name="communication_phone"
          className="create-account-checkbox"
          labelClassName="create-account-label"
          label={t('form.field.phone_label')}
          onClick={handleCheck}
        />
        <Checkbox
          name="communication_textMessage"
          className="create-account-checkbox"
          labelClassName="create-account-label"
          label={t('form.field.text_message')}
          onClick={handleCheck}
        />
      </div>
      {showDoubleConfirm && (
        <Tippy
          content={
            <Typography variant="body3" color="var(--color-white)">
              {t('communication_preferences.doubleOpt-tips')}
            </Typography>
          }
          animation="scale"
          offset="0,4"
          visible={showPreferenceTips}
          placement="top-start"
          zIndex={999999999}
        >
          <Checkbox
            name="doubleOpt"
            className="checkbox-tips"
            labelClassName="create-account-label"
            label={t('communication_preferences.doubleOpt')}
            onClick={() => {
              setShowPreferenceTips(false);
            }}
          />
        </Tippy>
      )}
      <div className="create-account-btn-wrapper">
        {!isMobile && (
          <Tippy
            className="validate-popup-wrapper"
            interactive
            animation={'perspective'}
            content={
              <VerificationModalContent
                isPopupVersion={true}
                data={data?.preRegisterStudentUser?.validation}
                loading={preRegisterStudentUserLoading}
                buttonProps={{
                  loading,
                  onClick: () => {
                    formik.submitForm();
                  },
                }}
                hasError={isVerificationError}
                onRefresh={() => {
                  handleRefresh(formik);
                }}
                property={formik.values?.interestedPropertyId}
              />
            }
            visible={showVerificationPop}
            zIndex={999999999}
          >
            <div style={{ width: '100%' }}>
              <Button
                loading={loading || preRegisterStudentUserLoading}
                className="create-account-btn"
                fullWidth
                type="button"
                onClick={() => {
                  handleValidate(formik);
                }}
              >
                {t('create_account.sign_up')}
              </Button>
            </div>
          </Tippy>
        )}
        {isMobile && (
          <Button
            loading={loading || preRegisterStudentUserLoading}
            className="create-account-btn"
            fullWidth
            type="button"
            onClick={() => {
              handleValidate(formik);
            }}
          >
            {t('create_account.sign_up')}
          </Button>
        )}
      </div>
      {isMobile && (
        <BaseModal
          isOpen={showVerificationPop}
          mobileHeightType={'auto'}
          notShowTitleBlank={true}
          wrapperStyle={{ width: '100%' }}
          style={{ overlay: { zIndex: 999999999 } }}
          childrenWrapperStyle={{
            overflow: 'hidden',
            padding: '32px 16px 24px',
          }}
          onClose={() => {
            formik.setFieldValue('validationToken', undefined);
            setShowVerificationPop(false);
          }}
          className="validate-modal"
        >
          <VerificationModalContent
            isPopupVersion={true}
            data={data?.preRegisterStudentUser?.validation}
            loading={preRegisterStudentUserLoading}
            buttonProps={{
              loading,
              onClick: () => {
                formik.submitForm();
              },
            }}
            hasError={isVerificationError}
            onRefresh={() => {
              handleRefresh(formik);
            }}
            property={formik.values?.interestedPropertyId}
          />
        </BaseModal>
      )}
      <style jsx>{`
        .create-account-communication {
          :global(&__label) {
            margin: 4px 0 12px;
          }
        }
        :global(.validate-popup-wrapper[data-placement^='top'] > .tippy-arrow) {
          border-top-color: var(--color-white);
        }
        :global(.validate-popup-wrapper) {
          background: var(--color-white);
          box-shadow: 1px 1px 8px 0 var(--color-outline);
        }
        :global(.validate-popup-wrapper .tippy-content) {
          padding: 12px 20px;
        }
        @media screen and (max-width: 768px) {
          .create-account-communication {
            :global(&__label) {
              margin: 4px 0 0;
            }
          }
        }
      `}</style>
    </Form>
  );
  return (
    <>
      <Typography variant="h4">{t('auth.create_account')}</Typography>
      <div className="login-form-welcome">
        <Typography variant="body3">{t(`create_account.description.${ORG}`)}</Typography>
        <Divider className="login-form-divider" />
      </div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          communication_email: false,
          communication_phone: false,
          communication_textMessage: false,
          heardSource: '',
          doubleOpt: false,
          interestedPropertyId: propertyId || undefined,
          mobilePhoneAreaCode: deduplicatePhoneCodes[0],
          mobilePhoneNumber: undefined,
          preferredLanguage: defaultLanguageOption,
          validationToken: undefined,
        }}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={CreateAccountSchema}
        component={createAccountFormWrapper}
        enableReinitialize
      />
      <style jsx>{`
        :global(.checkbox-tips) {
          width: 100%;
        }
        .login-form-welcome {
          display: none;
        }
        .create-account-footer {
          background: var(--color-greyFa);
          position: fixed;
          right: 0;
          bottom: 0;
          text-align: center;
          padding: 12px 0;
          width: ${isMobile ? '100%' : 'calc(100% - 550px)'};
        }
        :global(.cretae-account-form) {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          margin-top: 24px;
          justify-content: space-between;
        }
        :global(.create-account-input) {
          margin: ${isMobile ? '8px 0' : '0 0 20px'};
          width: ${isMobile ? '100%' : '313px'};
        }
        :global(.create-account-check) {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 36px;
        }
        :global(.create-account-btn-wrapper) {
          display: flex;
          align-items: center;
          justify-content: space-around;
          margin-top: 12px;
          margin-bottom: 16px;
          width: 100%;
        }
        :global(.create-account-label) {
          color: var(--color-purple_6);
        }
        :global(.create-account-group-head) {
          background: var(--color-background);
          color: var(--color-greyText);
          border-bottom: 1px solid var(--color-outline);
          padding: 8px 12px;
        }
        @media screen and (max-width: 768px) {
          .login-form-welcome {
            display: block;
            margin-top: 4px;
          }
          :global(.login-form-divider) {
            margin-top: 12px !important;
          }
          .create-account-footer {
            background: none;
            position: static;
            text-align: center;
            padding: 0;
          }
          :global(.create-account-checkbox) {
            width: 50%;
            margin-top: 16px;
          }
          :global(.create-account-btn-wrapper) {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export default CreateAccountForm;
