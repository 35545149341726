import { useMutation } from '@apollo/react-hooks';
import { UAContext } from '@quentin-sommer/react-useragent';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';
import Button from '~components/button';
import Divider from '~components/divider';
import { Input } from '~components/form';
import { useThemeContext } from '~components/theme-provider';
import Typography from '~components/typography';
import {
  SP_StudentForgotPassword,
  SP_StudentForgotPasswordVariables,
} from '~graphql/__generated__/SP_StudentForgotPassword';
import { STUDENT_FORGOT_PASSWORD } from '~graphql/user';
import { Trans, useTranslation } from '~i18n';
import { Tick } from '~svg-components';

interface Values {
  email: string;
}
type SetError = {
  (field: string, message: string): void;
};

interface IForgetPasswordFormProps {
  setModalType: (params: 'LOGIN' | 'CREATE_ACCOUNT' | 'FORGET_PASSWORD') => void;
  onBack?: () => void;
}

const countdown = 60;
const STUDENT_USER_NOT_FOUND = 'STUDENT_USER_NOT_FOUND';

const ForgetPasswordForm: React.FC<IForgetPasswordFormProps> = ({ setModalType, onBack }) => {
  const { t } = useTranslation(['student_portal', 'public']);
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const emailValidError = t('form.validation.format_error', {
    field_name: t('form.field.email_address').toLowerCase(),
  });

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(emailValidError)
      .required(),
  });
  const [count, setCount] = useState(0);
  const setFieldErrorFuc = useRef<SetError | null>(null);
  const { topLandlordId } = useThemeContext();

  const [sendEmail, { data, error }] = useMutation<
    SP_StudentForgotPassword,
    SP_StudentForgotPasswordVariables
  >(STUDENT_FORGOT_PASSWORD, {
    onCompleted: data => {
      setCount(countdown);
    },
    onError: ({ graphQLErrors }) => {
      const { message } = graphQLErrors[0] || {};
      if (message === STUDENT_USER_NOT_FOUND) {
        setFieldErrorFuc.current!('email', t('form.validation.user_not_find'));
      }
    },
  });

  const onSubmit = (values: Values, { setFieldError }: FormikHelpers<Values>) => {
    setFieldErrorFuc.current = setFieldError;
    sendEmail({
      variables: {
        ...values,
        topLandlordId,
      },
    });
  };

  const emailErrorComponent = useMemo(() => {
    if (error && error.message.includes(STUDENT_USER_NOT_FOUND)) {
      return (
        <Trans i18nKey="form.validation.user_not_find">
          No account found for this email.
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {
              setModalType('CREATE_ACCOUNT');
            }}
          >
            Create an account now?
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error, setModalType]);

  const renderButton = useMemo(
    () => (
      <Button
        size="large"
        fullWidth={isMobile}
        disabled={count !== 0}
        className="forgot-password-submit"
        type="submit"
      >
        {t('forgot_password.btn')}
        {count === 0 ? '' : `(${count}s)`}
      </Button>
    ),
    [count, isMobile, t]
  );
  const forgotPasswordForm = ({ values: formValues, isSubmitting }: FormikProps<Values>) => (
    <Form>
      <Input
        name="email"
        type="text"
        errorComponent={emailErrorComponent}
        label={t('form.field.email_address')}
        placeholder={t('form.field.email_address')}
      />
      {data?.studentForgotPassword?.success && (
        <div className="send-success">
          <Tick width={16} height={12} fill="var(--color-purple_6)" />
          <Typography variant="body3" className="success-text">
            {t('forgot_password.send.success')}
          </Typography>
        </div>
      )}
      {renderButton}
      <Typography
        fullWidth={isMobile}
        component={isMobile ? 'div' : 'span'}
        textDecoration="underline"
        textAlign="center"
        variant="body3"
        onClick={() => {
          if (onBack) {
            onBack();
          } else {
            setModalType('LOGIN');
          }
        }}
        className="forgot-password-back"
      >
        {t('back')}
      </Typography>
    </Form>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (count > 0) {
        setCount((c: number) => c - 1);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [count]);

  return (
    <div className="forget-password-component">
      <Typography variant="h4">{t('forgot_password.title')}</Typography>
      <Typography
        component="p"
        fullWidth
        variant={isMobile ? 'body3' : 'body1'}
        className="forgot-password-description"
      >
        {t('forgot_password.description')}
      </Typography>
      <Divider className="forget-password-divider" />
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={onSubmit}
        validateOnBlur
        validationSchema={ForgotPasswordSchema}
        component={forgotPasswordForm}
      />
      <style jsx>{`
        :global(.forgot-password-description) {
          margin-top: 16px;
          margin-bottom: 20px;
        }
        :global(.forget-password-divider) {
          margin-bottom: 40px !important;
        }
        .send-success {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          margin-top: 8px;
        }
        :global(.success-text) {
          margin-left: 8px;
        }
        :global(.forgot-password-submit) {
          margin-top: 32px;
        }
        :global(.forgot-password-back) {
          display: inline-block;
          cursor: pointer;
          margin-left: ${isMobile ? '0' : '24px'};
          margin-top: ${isMobile ? '20px' : '0'};
          margin-bottom: ${isMobile ? '20px' : '0'};
        }
        @media screen and (max-width: 768px) {
          .forget-password-component {
            padding: 0 16px;
          }
          :global(.forgot-password-description) {
            margin-top: 4px;
            margin-bottom: 12px;
          }
          :global(.forget-password-divider) {
            margin-bottom: 32px !important;
          }
        }
      `}</style>
    </div>
  );
};

export default ForgetPasswordForm;
