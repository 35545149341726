/* eslint-disable no-template-curly-in-string */
import { useTranslation } from '~i18n';
import { setLocale } from 'yup';

function useYupConfig() {
  const { t } = useTranslation();
  setLocale({
    mixed: {
      required: t('form.validation.missing_mandatory'),
      notOneOf: t('form.validation.format_error', { field_name: '${path}' }),
      notType: t('form.validation.format_error', { field_name: '${path}' }),
    },
    string: {
      email: t('form.validation.format_error', { field_name: '${path}' }),
      trim: t('form.validation.trim_error'),
    },
  });
}

export default useYupConfig;
