import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { UAContext } from '@quentin-sommer/react-useragent';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import Button from '~components/button';
import Divider from '~components/divider';
import { Input } from '~components/form';
import { useThemeContext } from '~components/theme-provider';
import { useToast } from '~components/toast/use-toast';
import Typography from '~components/typography';
import { Authorization } from '~constants/cookie-properties';
import { SP_QueryStudentViewer } from '~graphql/__generated__/SP_QueryStudentViewer';
import { SP_StudentLogin, SP_StudentLoginVariables } from '~graphql/__generated__/SP_StudentLogin';
import { STUDENT_LOGIN } from '~graphql/user';
import { QUERY_STUDENT_VIEWER } from '~graphql/user';
import { Router, Trans, useTranslation } from '~i18n';
import { updateViewerInfo } from '~store/user/actions';
import { setCookie } from '~utils/cookie-operation';
import { formatToUpper } from '~utils/js-helper';
import { ModalWrapper } from './user-modal';

interface Values {
  password: string;
}
type SetError = {
  (field: string, message: string): void;
};

interface ILoginWithEmailProps {
  isOpen: boolean;
  email: string;
  property: string;
  onSuccess?: () => void;
  onForgot?: () => void;
}
const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
const STUDENT_USER_NOT_FOUND = 'STUDENT_USER_NOT_FOUND';

const LoginWithEmail: React.FC<ILoginWithEmailProps> = ({
  isOpen,
  email,
  property,
  onSuccess,
  onForgot,
}) => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { t } = useTranslation(['student_portal', 'public']);
  const validError = t('form.validation.password');
  const submitCountRef = useRef<number>(0);
  const setFieldErrorFuc = useRef<SetError | null>(null);
  const { topLandlordId } = useThemeContext();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const [userNotFoundError, setUserNotFoundError] = useState(false);

  const [studentViewer] = useLazyQuery<SP_QueryStudentViewer>(QUERY_STUDENT_VIEWER, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (data.studentViewer) {
        dispatch(updateViewerInfo(data.studentViewer));
      }
    },
  });

  const [studentLogin, { loading, error }] = useMutation<SP_StudentLogin, SP_StudentLoginVariables>(
    STUDENT_LOGIN,
    {
      onCompleted: data => {
        const token = data.studentLogin?.authToken;
        if (token) {
          setCookie(Authorization, `Bearer ${token}`, 30);
          if (onSuccess) {
            setTimeout(() => {
              studentViewer();
              onSuccess();
            }, 300);
          }
        }
      },
      onError: ({ graphQLErrors }) => {
        const { message } = graphQLErrors[0] || {};
        if (message === STUDENT_USER_NOT_FOUND) {
          setUserNotFoundError(true);
        } else if (message === INVALID_CREDENTIALS) {
          setFieldErrorFuc.current!('password', t('form.validation.password_wrong_text'));
        } else {
          addToast(
            t('error.message', {
              errorMsg: `(${formatToUpper(message)})`,
            })
          );
        }
      },
    }
  );

  const loginSchema = Yup.object().shape({
    password: Yup.string()
      .trim()
      .min(8, validError)
      .matches(/(?=.*[a-zA-Z])(?=.*[0-9])./, validError)
      .required(),
  });

  const passwordErrorComponent = useMemo(() => {
    if (error && error.message.includes(INVALID_CREDENTIALS) && submitCountRef.current > 2) {
      return (
        <Trans i18nKey="form.validation.password_wrong">
          The password you entered is incorrect.
          <Typography
            color="var(--color-red_6)"
            variant="body3"
            textDecoration="underline"
            cursor="pointer"
            onClick={() => {}}
          >
            {{ resetText: t('auth.lost_password') }}
          </Typography>
        </Trans>
      );
    }
    return null;
  }, [error, t]);

  const onSubmit = async (values: Values, { setFieldError }: FormikHelpers<Values>) => {
    setFieldErrorFuc.current = setFieldError;
    studentLogin({
      variables: {
        ...values,
        email,
        topLandlordId,
      },
    });
  };

  const loginForm = ({ values: formValues, submitCount }: FormikProps<Values>) => {
    submitCountRef.current = submitCount;
    return (
      <Form style={{ marginTop: 40 }}>
        <Input
          name="password"
          type="password"
          errorComponent={passwordErrorComponent}
          label={t('form.field.password')}
          labelRight={
            <Typography
              variant="body3"
              textDecoration="underline"
              onClick={() => {
                if (onForgot) onForgot();
              }}
            >
              {t('login.forgot_password')}
            </Typography>
          }
          placeholder={t('form.field.password')}
        />
        <Button
          size="large"
          loading={loading}
          type="submit"
          fullWidth={true}
          style={{ marginTop: 32 }}
        >
          {t('auth.login')}
        </Button>
      </Form>
    );
  };

  return (
    <>
      <ModalWrapper isOpen={isOpen}>
        <div className="login-width-email">
          <Typography variant="h4" component="p">
            {t('auth.login')}
          </Typography>
          <Typography variant="body2" component="p" style={{ marginTop: isMobile ? 6 : 16 }}>
            {property && t('summary_page.login_in.title', { property })}
          </Typography>
          <Divider style={{ marginTop: 10 }} />
          <div className="login-width-email__email-info">
            <Typography variant="body3">{t('my_profile.email')}</Typography>
            <Typography variant="h9" style={{ marginLeft: 12 }}>
              {email}
            </Typography>
          </div>
          {userNotFoundError && (
            <Typography
              variant="body3"
              color="var(--color-red_6)"
              component="p"
              style={{ marginTop: 8 }}
            >
              <Trans i18nKey="form.validation.user_not_find">
                No account found for this email.
                <Typography
                  color="var(--color-red_6)"
                  variant="body3"
                  textDecoration="underline"
                  cursor="pointer"
                  onClick={() => {
                    Router.push('/create-account');
                  }}
                >
                  Create an account now?
                </Typography>
              </Trans>
            </Typography>
          )}

          <Formik
            initialValues={{
              password: '',
            }}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={loginSchema}
            component={loginForm}
          />
        </div>
      </ModalWrapper>
      <style jsx>{`
        .login-width-email {
          &__email-info {
            background: ${userNotFoundError ? 'var(--color-red_1)' : 'var(--color-background)'};
            border-radius: 14px;
            padding: 12px 16px;
            margin-top: 32px;
          }
        }
        @media screen and (max-width: 768px) {
          .login-width-email {
            padding: 0 16px;
          }
        }
      `}</style>
    </>
  );
};

export default LoginWithEmail;
