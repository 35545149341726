import { useMutation } from '@apollo/react-hooks';
import { UAContext } from '@quentin-sommer/react-useragent';
import { Form, Formik, FormikProps } from 'formik';
import React, { useContext, useState } from 'react';
import * as Yup from 'yup';
import Button from '~components/button';
import Divider from '~components/divider';
import { Input } from '~components/form';
import { useToast } from '~components/toast/use-toast';
import Typography from '~components/typography';
import {
  SP_ResetStudentUserPassword,
  SP_ResetStudentUserPasswordVariables,
} from '~graphql/__generated__/SP_ResetStudentUserPassword';
import { RESET_STUDENT_USER_PASSWORD } from '~graphql/user';
import { useTranslation } from '~i18n';
import { IconEyeHide, IconEyeShow, IconSuccess } from '~svg-components';
import { formatToUpper } from '~utils/js-helper';
import styles from './set-password.module.scss';
import { ModalWrapper } from './user-modal';

interface Values {
  password: string;
}

interface ILoginFormProps {
  isOpen: boolean;
  property: string;
  partner: string;
  email: string;
  token: string;
  handleSuccess: () => void;
}

const LoginForm: React.FC<ILoginFormProps> = ({
  isOpen,
  property,
  partner,
  email,
  token,
  handleSuccess,
}) => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { t } = useTranslation(['student_portal', 'public']);
  const [passwordType, setPasswordType] = useState('password');
  const [successfully, setSuccessfully] = useState(false);
  const { addToast } = useToast();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .trim()
      .min(8, t('form.validation.password'))
      .matches(/(?=.*[a-zA-Z])(?=.*[0-9])./, t('form.validation.password'))
      .required(),
  });

  const [resetPassword, { loading }] = useMutation<
    SP_ResetStudentUserPassword,
    SP_ResetStudentUserPasswordVariables
  >(RESET_STUDENT_USER_PASSWORD, {
    onCompleted: () => {
      setSuccessfully(true);
    },
    onError: ({ graphQLErrors }) => {
      const { message } = graphQLErrors[0] || {};
      addToast(
        t('error.message', {
          errorMsg: `(${formatToUpper(message)})`,
        })
      );
    },
  });

  const onSubmit = (values: Values) => {
    resetPassword({
      variables: {
        ...values,
        token,
      },
    });
  };

  const resetPasswordForm = ({ values: formValues }: FormikProps<Values>) => (
    <Form style={{ marginTop: 40 }}>
      <Input
        name="password"
        type={passwordType}
        label={t('form.field.new_password')}
        placeholder={t('form.create.password')}
        labelRight={
          passwordType === 'password' ? (
            <IconEyeHide
              onClick={() => {
                setPasswordType('text');
              }}
              width={16}
              height={13}
            />
          ) : (
            <IconEyeShow
              onClick={() => {
                setPasswordType('password');
              }}
              width={16}
              height={13}
            />
          )
        }
      />
      <Button
        size="large"
        loading={loading}
        fullWidth={true}
        type="submit"
        style={{ marginTop: 28 }}
      >
        {t('reset_password.btn')}
      </Button>
    </Form>
  );

  return (
    <div>
      <ModalWrapper isOpen={isOpen && !successfully}>
        <div style={{ padding: '0 16px' }}>
          <Typography variant={isMobile ? 'h7' : 'h5'} component="p">
            {t('my_bookings.congratulations')}
          </Typography>
          <Typography variant={isMobile ? 'h7' : 'h5'} component="p">
            {t('summary_page.accomodation.title', { property })}
          </Typography>
          <div className={styles.partner}>
            <Typography variant="body3" color="var(--color-purple_4)" component="p">
              {t('summary_page.partnership.with')}
            </Typography>
            <Typography variant="h8" component="p" style={{ marginTop: 2 }}>
              {formatToUpper(partner)}
            </Typography>
          </div>
          <Typography variant="body2" component="p" style={{ marginTop: 32 }}>
            {t('summary_page.set_password.title')}
          </Typography>
          <Divider style={{ marginTop: isMobile ? 12 : 20 }} />
          <div className={styles.email}>
            <Typography variant="body3">{t('my_profile.email')}</Typography>
            <Typography variant="h9" style={{ marginLeft: 12 }}>
              {email}
            </Typography>
          </div>

          <Formik
            initialValues={{
              password: '',
            }}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={ResetPasswordSchema}
            component={resetPasswordForm}
          />
        </div>
      </ModalWrapper>
      <ModalWrapper isOpen={isOpen && successfully}>
        <div className={styles.success}>
          <IconSuccess width={82} height={82} />
          <Typography variant={isMobile ? 'h7' : 'h5'} style={{ marginTop: 32 }}>
            {t('summary_page.set_password.successfully')}
          </Typography>
          <Typography variant="body3" style={{ marginTop: 12 }}>
            {t('summary_page.step.login')} <Typography variant="h9">{property}</Typography>.
          </Typography>
          <div className={styles.partner}>
            <Typography variant="body3" color="var(--color-purple_4)" component="p">
              {t('summary_page.partnership.with')}
            </Typography>
            <Typography variant="h8" component="p" style={{ marginTop: 2 }}>
              {formatToUpper(partner)}
            </Typography>
          </div>
          <Button size="large" style={{ marginTop: 48 }} onClick={handleSuccess}>
            {t('auth.login')}
          </Button>
        </div>
      </ModalWrapper>
    </div>
  );
};

export default LoginForm;
