import { UAContext } from '@quentin-sommer/react-useragent';
import React, { useContext, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import ToastItem from './toast-item';

interface IToastPortal {
  toasts: any;
}
const ToastPortal: React.FC<IToastPortal> = ({ toasts }) => {
  const root = useRef<HTMLDivElement | null>(null);
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  useEffect(() => {
    root.current = document.createElement('div');
    root.current.id = '__react-alert__';
    document.body.appendChild(root.current);

    return () => {
      if (root.current) document.body.removeChild(root.current);
    };
  }, []);

  const toastContainer = () => {
    return (
      <div className="toast-portal">
        {toasts.map((item: any) => {
          return <ToastItem uuid={item.id} key={item.id} text={item.content} type={item?.type} />;
        })}
        <style jsx>
          {`
            .toast-portal {
              position: fixed;
              margin: 0 auto;
              left: 50%;
              width: ${isMobile ? '100%' : 'auto'};
              padding: 0 20px;
              transform: translate(-50%, 0);
              bottom: 100px;
              z-index: 50000000;
            }
          `}
        </style>
      </div>
    );
  };
  return <>{root.current && createPortal(toastContainer(), root.current)}</>;
};

export default ToastPortal;
