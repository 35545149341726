import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Typography from '~components/typography';
import { CreateAccountForm, ForgetPassworForm, LoginForm } from '~components/user';
import { useTranslation } from '~i18n';
import { Close } from '~svg-components';
interface IModalWrapperProps {
  isOpen: boolean;
  onClose?: () => void;
}

export const ModalWrapper: React.FC<IModalWrapperProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        className="user-modal"
        overlayClassName="user-modal__overlay"
        onAfterOpen={() => {
          document.body.style.position = 'fixed';
          document.body.style.overflow = 'hidden';
          document.body.style.width = '100%';
        }}
        onAfterClose={() => {
          document.body.style.position = 'static';
          document.body.style.overflow = 'initial';
        }}
      >
        <div className="user-modal__wrapper">
          <div className="user-modal__header">
            {onClose && (
              <Close
                width={16}
                height={16}
                fill="var(--color-greyText)"
                onClick={() => {
                  onClose();
                }}
              />
            )}
          </div>

          {onClose && (
            <Close
              width={20}
              height={20}
              fill="var(--color-greyText)"
              className="user-modal__close"
              onClick={() => {
                onClose();
              }}
            />
          )}

          {children}
        </div>
      </ReactModal>
      <style jsx>{`
        .user-modal {
          &__header {
            padding: 16px;
            display: none;
          }
          &__wrapper {
            width: 762px;
            padding: 56px;
            max-height: 95%;
            overflow: scroll;
            border-radius: 4px;
            border-top: 8px solid var(--color-yellow_6);
            background-color: var(--color-white);
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-image: linear-gradient(
              to bottom,
              #fffde4,
              var(--color-white) 16%,
              var(--color-white)
            );
          }
        }
        :global(.user-modal) {
          width: 100%;
          height: 100%;
          position: relative;
        }
        :global(.user-modal__close) {
          position: absolute;
          top: 24px;
          right: 24px;
          cursor: pointer;
        }
        :global(.user-modal__overlay) {
          position: fixed;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 50000000;
          background-color: rgba(76, 76, 76, 0.6);
        }
        @media screen and (max-width: 768px) {
          .user-modal {
            &__header {
              display: block;
            }
            &__wrapper {
              width: 100%;
              height: 100%;
              max-height: 100%;
              padding: 0;
              border-radius: 0;
              border-top: none;
              position: static;
              -webkit-transform: none;
              -moz-transform: none;
              transform: none;
              background-image: none;
            }
          }
          :global(.user-modal__close) {
            display: none;
          }
          :global(.user-modal__close) {
            position: absolute;
            top: 24px;
            right: 24px;
            cursor: pointer;
          }
        }
      `}</style>
    </>
  );
};

type ModalType = 'LOGIN' | 'CREATE_ACCOUNT' | 'FORGET_PASSWORD';

interface IUserModalProps {
  propertyId?: string;
  isOpen: boolean;
  type: ModalType;
  onClose: () => void;
  onConfirm?: () => void;
  onBack?: () => void;
}

const UserModal: React.FC<IUserModalProps> = ({
  isOpen,
  type,
  propertyId,
  onClose,
  onConfirm,
  onBack,
}) => {
  const { t } = useTranslation(['student_portal', 'public']);
  const [modalType, setModalType] = useState<ModalType>(type);

  useEffect(() => {
    if (type) {
      setModalType(type);
    }
  }, [type]);
  return (
    <div>
      <ModalWrapper isOpen={isOpen} onClose={onClose}>
        {modalType === 'LOGIN' && (
          <>
            <LoginForm
              onSuccess={() => {
                if (onConfirm) onConfirm();
              }}
              setModalType={setModalType}
            />
            <Typography variant="body2" component="div" textAlign="center">
              {t('auth.not_have_account')} &nbsp;
              <Typography
                variant="body3"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => {
                  setModalType('CREATE_ACCOUNT');
                }}
              >
                {t('auth.create_account')}
              </Typography>
            </Typography>
          </>
        )}
        {modalType === 'CREATE_ACCOUNT' && (
          <div className="create-account-form-component">
            <CreateAccountForm
              setModalType={setModalType}
              propertyId={propertyId}
              onSuccess={() => {
                if (onConfirm) onConfirm();
              }}
            />
            <Typography variant="body2" component="div" textAlign="center">
              {t('auth.have_account')} &nbsp;
              <Typography
                variant="body3"
                textDecoration="underline"
                cursor="pointer"
                onClick={() => {
                  setModalType('LOGIN');
                }}
              >
                {t('auth.login')}
              </Typography>
            </Typography>
          </div>
        )}
        {modalType === 'FORGET_PASSWORD' && (
          <ForgetPassworForm
            setModalType={setModalType}
            onBack={() => {
              if (onBack) onBack();
            }}
          />
        )}
      </ModalWrapper>
      <style jsx>{`
        @media screen and (max-width: 768px) {
          .create-account-form-component {
            padding: 0 16px 80px;
            width: 100%;
            height: 100%;
            overflow-y: scroll;
          }
        }
      `}</style>
    </div>
  );
};

export default UserModal;
