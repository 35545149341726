import React, { useCallback, useState } from 'react';
import ToastPortal from './toast-portal';

export const ToastContext = React.createContext<any>(null);

let id = 1;

const ToastProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<any>([]);

  const addToast = useCallback(
    (content, type) => {
      if (process.browser) {
        setToasts((toasts: any[]) => [...toasts, { id: id++, content, type }]);
      }
    },
    [setToasts]
  );

  const removeToast = useCallback(
    id => {
      setToasts((toasts: any[]) => toasts.filter(t => t.id !== id));
    },
    [setToasts]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      <ToastPortal toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
